import { Component, Input } from '@angular/core';

import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-search-input',
  standalone: true,
  imports: [InputTextModule, ReactiveFormsModule],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss'
})
export class SearchInputComponent {

  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() control!: FormControl | any;
  @Input() name: string = '';
  @Input() type: 'text' | 'password' | 'email'  = 'text';
  @Input() secondary: boolean = false;
  @Input() outline: boolean = false;
  @Input() globalSearch: boolean = false;
  @Input() mobileOnly!: boolean;
  @Input() desktopOnly!: boolean;
  @Input() secondaryMd: boolean = false;
  @Input() loading: boolean = false;
  @Input() clearable: boolean = false;



  public clearControl(){
    this.control.setValue('');
  }

}
